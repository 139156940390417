<template>
  <div class="main-app">
    <Header></Header>
    <div
      class="main-bottom">
      <div class="content-right">
        <keep-alive>
          <a-config-provider :locale="zhCN">
            <router-view />
          </a-config-provider>
        </keep-alive>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { MessageOutlined } from "@ant-design/icons-vue";
import { loginOut } from "/@/api/app/index";
import { message } from "ant-design-vue";
import { storeToRefs } from "pinia";
import zhCN from "ant-design-vue/es/locale/zh_CN";
import { routesWhiteListEnum } from "/@/utils/constant";
import { useUserStore } from "/@/store/index";
import  Header  from "/@/layouts/components/Header.vue";
import Footer from '/@/layouts/components/Footer.vue';
const router = useRouter();
const route = useRoute();
const userStore = useUserStore();
const { nickname, headImg } = storeToRefs(userStore);
const routesWhiteList = ref([]);
routesWhiteList.value = routesWhiteListEnum;
const locale = ref();
locale.value = zhCN;
onMounted(() => {});
//退出登录
async function exit() {
  let token = localStorage.getItem("token");
  await loginOut({ tokenValue: token });
  message.success("已退出登录");
  // 清空token
  localStorage.clear();
}

</script>
<style lang="less">

#app::-webkit-scrollbar {
    display: none;
  }

.main-app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  // overflow: hidden;
  .main-top {
    width: 100%;
    height: 60px;
    border-radius: 0px 0px 8px 8px;
    opacity: 1;
    border: 1px solid #252729;
    display: flex;
    justify-content: space-between;

    .main-head {
      width: 100%;
      line-height: 60px;
      height: 60px;
      display: flex;
      align-items: center;

      img {
        width: 32px;
        height: 32px;
        margin-left: 24px;
        margin-right: 12px;
      }

      span {
        font-family: "PingFang SC";
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 34px;
        background: linear-gradient(152.97deg, #2292c1 16.89%, #ab42dd 83.64%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }

    .main-top-user-feedback {
      margin-right: 28px;
      display: flex;
      justify-content: center;
      align-items: center;

      .user {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        img {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          object-fit: cover;
        }

        .nikeName {
          display: inline-block;
          width: 88px;
          font-size: 14px;
          color: #fff;
          margin-left: 16px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }

      .feedback {
        width: 80px;
        margin-left: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .feedback-text {
          color: #999999;
          margin-left: 8px;
        }
      }
    }
  }

  .main-bottom {
    flex: 1;
    width: 100%;
    // height: 110%;
    // overflow: hidden;
    display: flex;
    .content-left {
      height: 100%;

      .main-content {
        height: 100%;
      }
    }

    .content-right {
      width: 100%;
      height: 100%;
      // background: #000;
      flex: 1;
      overflow: hidden;
    }
  }
}
</style>
// 权限问题后期增加
import { get, post } from '/@/utils/http/axios';
import { UserState } from '/@/store/modules/user/types';
const VITE_APP_REMOTE_API_PREFIX = import.meta.env.VITE_REMOTE_API_PREFIX

enum URL {
  login = '/auth/user/login',
  logout = '/auth/user/logout',
  userInfo = '/auth/sys/users/info',
}

interface LoginRes {
  token: string;
}

export interface LoginData {
  username: string;
  password: string;
}

const login = async (data: LoginData) => post<any>({ url: VITE_APP_REMOTE_API_PREFIX + URL.login, data });
const logout = async () => post<LoginRes>({ url: VITE_APP_REMOTE_API_PREFIX + URL.logout });
const getUserInfo = async () => get<UserState>({ url: VITE_APP_REMOTE_API_PREFIX + URL.userInfo });

export { getUserInfo, logout, login };

<template>
  <div class="overlay" @click.stop="closeLogin">
    <div class="login" @click.stop>
      <h3>登录</h3>
      <a-form :model="formState" name="normal_login" class="login-form">
        <a-form-item name="username" :rules="[{ required: true, message: '请输入你的账号!' }]">
          <a-input v-model:value="formState.username" placeholder="请输入账号" @click.stop>
            <template #prefix>
              <MobileOutlined class="site-form-item-icon" />
            </template>
          </a-input>
        </a-form-item>
        <a-form-item name="password" :rules="[{ required: true, message: '请输入你的密码!' }]">
          <a-input-password v-model:value="formState.password" placeholder="请输入密码" @click.stop>
            <template #prefix>
              <LockOutlined class="site-form-item-icon" />
            </template>
          </a-input-password>
        </a-form-item>

        <a class="login-forgot" href="https://a-test.izsw.net/resetpassword" @click.stop>忘记密码?</a>
        <a-form-item>
          <a-button type="primary" html-type="submit" class="login-form-button" @click="loginModel" @click.stop>
            登 录
          </a-button>
        </a-form-item>
        <div class="register">没有账号?<span @click="register">请注册</span></div>
      </a-form>
    </div>
  </div>
</template>
<script setup>
import { ref, reactive, computed, defineEmits, onMounted } from 'vue';
import { MobileOutlined, LockOutlined } from '@ant-design/icons-vue';
import { notification } from 'ant-design-vue';
import { useUserStore } from '/@/store';
const emit = defineEmits(['closeLoginModal', 'loginSuccess']);
const formState = reactive({
  username: '',
  password: '',
  remember: true,
});
const showLoginModal = ref(false);
const userStore = useUserStore();
const getuserinfo = ref()
const loginModel = async () => {
  showLoginModal.value = true
  if (formState.password == "" || formState.username == "") {
    return
  } else {
    try {
      const userInfo = await userStore.login({
        pwd: formState.password,
        phoneNo: formState.username,
        rememberMe: true,
      });
      if (userInfo.code == 200 && userInfo.data) {
        // 获取用户信息
        localStorage.setItem('USER_ROLES', userInfo.data.roles);  // 存储用户角色信息
        localStorage.setItem('ROLES__KEY__', userInfo.data.roles);  // 存储用户角色信息
        localStorage.setItem('PERMISSIONS__KEY__', userInfo.data.permissions);  // 存储用户权限


        const response = await userStore.getInfo();
        if (response.code == 200) {
          getuserinfo.value = response.data;
        }
        emit('loginSuccess', getuserinfo.value);
        emit('closeLoginModal', false);
        notification.success({
          message: '登录成功',
          description: `欢迎回来: ${userInfo.data.nickname}`,
          duration: 3,
        });
        const currentHost = window.location.origin;
        const ipWithCommunity = `${currentHost}/admin`;
        window.location.href = ipWithCommunity

      }
    } finally {
    }
  }

}

const closeLogin = () => {
  emit('closeLoginModal', false);
};
const register = () => {
  window.location.href = 'https://vdp.izsw.net/invite'
}
</script>
<style scoped lang="less">
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(0 0 0 / 50%);

  /* 半透明黑色背景，用作遮罩层 */
  display: flex;
  justify-content: center;
  align-items: center;
}

.login {
  background-image: url('https://zsw-bimface-res.izsw.net/img/bim-community/Loginbackground.png');
  background-size: cover;
  width: 480px;
  height: 482px;
  box-shadow: #a94afb;
  border-radius: 8px;
  opacity: 1;
  z-index: 999;
  position: relative;

  h3 {
    position: absolute;
    top: 61px;
    left: 80px;
    font-size: 35px;
    font-weight: 900;
  }

  .ant-input-affix-wrapper {
    width: 320px;
    height: 48px;
  }

  :deep(input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill) {
    -webkit-text-fill-color: #000 !important;
  }

  rgb(255, 255, 255) .ant-form-item-control-input-content input {
    width: 300px;
    height: 48px;
  }

  .ant-form {
    width: 320px;
    margin: 160px 0 0 80px;
  }

  .login-forgot {
    position: absolute;
    right: 80px;
    color: #999;
    margin-top: -15px;
  }

  .ant-btn-primary {
    width: 320px;
    height: 40px;
    background: linear-gradient(134deg, #16A6E7 0%, #AA49FB 100%);
    border-radius: 8px;
    border: 0;
    font-size: 16px;
    margin-top: 35px;
  }

  .register {
    color: #999;
    cursor: pointer;

    span {
      display: inline-block;
      background-image: linear-gradient(135deg, #16A6E7 0%, #AE47FC 100%);
      color: transparent;
      background-clip: text;
    }
  }
}
</style>
<style lang="less">
@import url('/@/styles/index.less');
</style>

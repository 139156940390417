<template>
    <div class="footer">
        <div class="content">
            <div class="top">
                <div class="left">
                    <div class="one"><span>友情链接</span> <span>联系我们</span></div>
                    <div class="two"><span @click="zsw">展视网官网</span> <span>展视网（北京）科技有限公司</span></div>
                    <div class="three"><span @click="zswbim">展视网BIM720</span> <span>地&nbsp;&nbsp;&nbsp;&nbsp;址:北京市房山区弘安路85号 <br></span>
                     <span>中关村新兴产业前沿技术研究院展视网数字大厦</span>  </div>
                </div>
                <div class="right">
                    <img src="https://zsw-bimface-res.izsw.net/img/bim-community/FoterLOGO.png" alt="">
                </div>
            </div>
            <div class="bottom">
                <span>展视网（北京）科技有限公司 京ICP证15040149号-1</span>
                <span>Copyright © 2016 - 2023 All Rights Reserved.</span>
            </div>
        </div>
    </div>
</template>
<script setup>
const zswbim = () => {
    window.open('https://bim720.izsw.net/')
}
const zsw = () => {
    window.open('http://www.izsw.net/')
}
</script>
<style scoped lang="less">
.footer {
    user-select:none;
    width: 100%;
    height: 222px;
    background: #303346;
    position: relative;
    bottom: 0;
    @media (max-width: 1200px) {
        .content {
            width: 800px;
            padding: 0 10px;
            margin-left: 0;
            .top {
                width: 600px;
            }

            .left {
                width: 600px;
                height: 100%;
                //   display: flex;
            }

            .right {
                margin-left: -200px;
            }

        }
    }

    .content {
        width: 1200px;
        height: 222px;
        margin: 0 auto;
        .top {
            height: 170px;
            border-bottom: 1px solid #404040;
            display: flex;

            .left {
                width: 800px;
                height: 100%;
                padding: 0 0 0 10px;
                margin-top: 20px;

                .one {
                    width: 500px;
                    span {
                        font-size: 14px;
                        font-weight: 500;
                        color: #FFF;
                    }

                    span:nth-child(2) {
                        margin-left: 80px;
                    }
                }

                .two {
                    width: 500px;
                    margin-top: 20px;

                    span {
                      font-size: 12px;
                        font-weight: 400;
                        color: #818498;

                    }

                    span:nth-child(1) {
                        cursor: pointer;
                    }

                    span:nth-child(2) {
                        margin-left: 80px;
                    }
                }

                .three {
                    width: 640px;
                    margin-top: 20px;

                    span {
                      font-size: 12px;
                        font-weight: 400;
                        color: #818498;
                    }

                    span:nth-child(1) {
                        cursor: pointer;
                    }

                    span:nth-child(2) {
                        margin-left: 60px;
                    }
                    span:nth-child(3) {
                        margin-left: 180px;
                    }
                }

                .four {
                    width: 400px;
                    margin-left: 190px;
                    font-weight: 400;
                    color: #818498;
                }
            }

            .right {
                height: 100%;
                margin-left: 254px;
                margin-top: 40px;
                img{
                    width: 120px;
                }
            }
        }

        .bottom {
            width: 1200px;
            height: 50px;
            font-size: 8px;
            font-weight: 500;
            color: #818498;
            line-height: 50px;
            display: flex;
            justify-content: space-between;
            padding: 0 25px 0 10px;
        }
    }

}
</style>
import { createRouter, createWebHashHistory, createWebHistory } from 'vue-router';
import generatedRoutes from 'virtual:generated-pages';
import { setupLayouts } from 'virtual:generated-layouts'
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { useUserStore, useMyPromptStore } from '/@/store';
import { routesWhiteListEnum } from '/@/utils/constant'

var routes = setupLayouts(generatedRoutes)
routes.push({
  path: '/',
  redirect: '/home',
  meta:{keepAlive:true}
});
//导入生成的路由数据
const router = createRouter({
  history: createWebHistory('/bim-community'),
  routes,
});


router.beforeEach(async (_to, _from, next) => {
  NProgress.start();
  //获取浏览器本地缓存的token
  const hasToken = localStorage.getItem("token");
  // 如果存在token 直接放行
  if (hasToken) {
    const userStore = useUserStore();
    await userStore.getInfo()
    next()
  } else {
    // 判断是否是白名单路径
    if (routesWhiteListEnum.indexOf(_to.path) !== -1) {
      next()
    } else {
      // 增加重定向
      next()
    }
  }
});

router.afterEach((_to) => {
  NProgress.done();
});

export default router;

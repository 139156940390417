import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import piniaStore from './store';
import * as qiniu from 'qiniu-js'
import 'ant-design-vue/dist/antd.css';
import zh_CN from "ant-design-vue/lib/locale-provider/zh_CN";
import { VueMasonryPlugin } from 'vue-masonry';
import '/@/styles/index.less';
import '/@/styles/reset.less';
import 'uno.css';
import { message } from 'ant-design-vue';
import moment from 'moment'
moment.locale("zh-cn");
// 支持SVG
import 'virtual:svg-icons-register';
import Tool from '/@/utils/tool'

//引入iconfont
import '/@/assets/iconfont/iconfont.css'

const app = createApp(App);
app.config.globalProperties.$Message = message
app.config.globalProperties.$moment = moment
app.config.globalProperties.$qiniu = qiniu


// 预览组件以及样式
import VMdPreview from '@kangc/v-md-editor/lib/preview';
import '@kangc/v-md-editor/lib/style/preview.css';
// VuePress主题以及样式（这里也可以选择github主题）
import vuepressTheme from '@kangc/v-md-editor/lib/theme/vuepress.js';
import '@kangc/v-md-editor/lib/theme/style/vuepress.css';

// Prism
import Prism from 'prismjs';
// 代码高亮
import 'prismjs/components/prism-json';
// 选择使用主题
VMdPreview.use(vuepressTheme, {
  Prism,
});
import createCopyCodePlugin from '@kangc/v-md-editor/lib/plugins/copy-code/index';
import '@kangc/v-md-editor/lib/plugins/copy-code/copy-code.css';

VMdPreview.use(createCopyCodePlugin());

app.use(VueMasonryPlugin);

// 引入v-md-editor预览组件
app.use(VMdPreview);
app.use(router);
app.use(piniaStore);
app.mount('#app');

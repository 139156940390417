

import __layout_0 from '/var/lib/jenkins/workspace/QL-bim-community/src/layouts/default.vue'
export const layouts = {
'404': () => import('/var/lib/jenkins/workspace/QL-bim-community/src/layouts/404.vue'),
'default': __layout_0,
'components/Footer': () => import('/var/lib/jenkins/workspace/QL-bim-community/src/layouts/components/Footer.vue'),
'components/Header': () => import('/var/lib/jenkins/workspace/QL-bim-community/src/layouts/components/Header.vue'),
}

export function setupLayouts(routes) {
  return routes.map(route => {
    return { 
      path: route.path,
      meta: route.meta,
      component: layouts[route.meta?.layout || 'default'],
      children: route.path === '/' ? [route] : [{...route, path: ''}]
    }
  })
}
